import React from 'react'
import { Link } from "gatsby"
import homeServices from '../css_modules/homeservices.module.scss'
import globalStyle from '../css_modules/global.module.scss'

const HomeServicesSection = () => {
    return (
        <section className={homeServices.homeServicesSection}>
            <div className={homeServices.sectionLeft}>
                <div className={globalStyle.container}>
                    <h2 className={globalStyle.heroTitle}>The services that we provide<br /><label> with impact</label></h2>
                </div>
                <div className={globalStyle.grayBackground}>
                    <div className={globalStyle.container}>
                        <div className={homeServices.servicesList}>
                            <div className={homeServices.servicesBox}>
                                <div className={globalStyle.circleIco + ' ' + globalStyle.mainIco + ' ' + homeServices.icons + ' ' + homeServices.circleIco}></div>
                                <h3>Digital marketing <br />strategy</h3>
                                <p>Whether you are planning to increase your revenues, digital prospects or bind your current customers to more loyalty and interaction you need an overall digital marketing strategy. Not sure how to connect across all channels? We can build you the right digital strategy.</p>
                            </div>
                            <div className={homeServices.servicesBox}>
                                <div className={globalStyle.playIco + ' ' + globalStyle.mainIco + ' ' + homeServices.icons + ' ' + homeServices.playIco}></div>
                                <h3>content & <br />lead marketing</h3>
                                <p>True engagement success comes through unique and personalised content. Making sure to reach your audience with what matters most to them is key. We can help you to find out how.</p>
                            </div>
                            <div className={homeServices.servicesBox}>
                                <div className={globalStyle.techIco + ' ' + globalStyle.mainIco + ' ' + homeServices.icons + ' ' + homeServices.techIco}></div>
                                <h3>marketing automation <br /> & technologies</h3>
                                <p>Connecting your marketing systems (CMS, CRM, asset management databases, campaign tools etc.) to one key data journey will determine your real time offers and prospect attention. We will build you marketing technology of the future.</p>
                            </div>
                        </div>
                    </div>
                    <div className={globalStyle.container}>
                        <div className={homeServices.servicesListContent}>
                            <p>We use <strong>state of the art services</strong> and <strong>lead marketing methods</strong>, and customise to your needs and requirements.</p>
                            <Link to="our-services" className={globalStyle.mainButton}>More information</Link>
                        </div>
                    </div>
                    <div className={homeServices.sectionRight}>
                        <div className={homeServices.imgHolder}></div>
                    </div>
                </div>
            </div>
            {/* <div className={homeServices.sectionRight}>
                <div className={homeServices.imgHolder}></div>
            </div> */}

        </section >
    )
}

export default HomeServicesSection