import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeHeroSection from "../components/homepage/homehero"
import HomeServicesSection from "../components/homepage/homeservices"
import HomeAboutSection from '../components/homepage/homeabout'
import HomeClientsSection from '../components/homepage/homeclients'
import HomeNewsletterSection from '../components/homepage/homenewsletter'


const IndexPage = () => (
  <Layout>
    <SEO title="Digital Consultancy in Frankfurt | Digital Madness" />
    <HomeHeroSection />
    <HomeServicesSection />
    <HomeAboutSection />
    <HomeClientsSection />
    <HomeNewsletterSection />
  </Layout>
)

export default IndexPage
