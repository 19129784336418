import React from 'react'
import { Link } from "gatsby"
import homeAbout from '../css_modules/homeabout.module.scss'
import globalStyle from '../css_modules/global.module.scss'



const HomeAboutSection = () => {
    return (
        <section className={homeAbout.homeAboutSection + ' ' + globalStyle.grayBackground}>
            <div className={homeAbout.sectionLeft}>
                <div className={homeAbout.imgHolder}></div>
                <div className={homeAbout.overlayDiv}></div>
            </div>
            <div className={homeAbout.sectionRight}>
                <div className={globalStyle.container}>
                    <h2 className={globalStyle.heroTitle}>find out<br /><label> who we are</label></h2>
                    <div className={homeAbout.aboutContent}>
                        <p>It's getting noisy out there in the <strong>digital world</strong>. As digital natives, we believe no technology, project, product or service will run successfully without <strong>smart digital marketing</strong> in place.</p>
                        <Link to="our-story" className={globalStyle.mainButton}>Get to know us</Link>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default HomeAboutSection